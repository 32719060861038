function handleImageError(event) {
  if (event.target.tagName === 'IMG') {
    const defaultImagePath = '/imgr/default_thumb_girl_transparent.svg';
    if (!event.target.src.includes(defaultImagePath)) {
      setTimeout(() => {
        event.target.style.backgroundColor = '#fff';
        event.target.src = defaultImagePath;
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('img').forEach((img) => {
    img.addEventListener('error', handleImageError);
  });
  document.body.addEventListener('error', handleImageError, true);
});
