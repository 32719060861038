document.addEventListener('DOMContentLoaded', function () {
  Sceon.PrivateMessaging = {};

  Sceon.PrivateMessaging.selector = 'sc-and6-messenger';

  Sceon.PrivateMessaging.StartChat = (profileId, profileType) => {
    const messenger = document.querySelector(Sceon.PrivateMessaging.selector);
    if (!messenger) {
      Sceon.Popup.Show();
      return;
    }

    messenger.startChat = { profileId, profileType };
  };

  if (document.querySelector(Sceon.PrivateMessaging.selector)) {
    setTimeout(
      () => {
        loadAndInitMessengerJS();
        checkBottomElements();
        checkGoToTopElement();
      },
      window.innerWidth < 769 ? 3000 : 0,
    );
  }

  document
    .querySelector('.status-messages-close')
    ?.addEventListener('click', () => {
      setTimeout(() => {
        checkBottomElements();
        checkGoToTopElement();
      });
    });

  function loadAndInitMessengerJS() {
    // Load Video.js JavaScript if it's not loaded
    const script = document.createElement('script');
    script.src = '/js/and6-messenger.js';
    script.async = true;
    document.head.appendChild(script);
  }

  function checkBottomElements() {
    let elmHeight = 0;
    if (document.getElementById('latestStatusMessagesCarousel')) {
      elmHeight = 99;
    }
    if (document.querySelector('.profile-bottom-sheet')) {
      elmHeight = 60;
    }
    if (document.querySelector('.search-page-form .actions-group')) {
      elmHeight = 73;
    }

    document.documentElement.style.setProperty(
      '--sc-msg-mobile-mb',
      `${elmHeight}px`,
    );
  }

  function checkGoToTopElement() {
    let elmHeight = 16;
    if (document.querySelector('.status-messages-close')) {
      elmHeight = 116;
    }
    if (document.querySelector('.profile-bottom-sheet')) {
      elmHeight = 76;
    }
    if (document.querySelector('.search-page-form .actions-group')) {
      elmHeight = 90;
    }

    document.documentElement.style.setProperty('--sc-gt-mb', `${elmHeight}px`);
  }

  setTimeout(() => {
    checkGoToTopElement();
  }, 200);
});
