document.addEventListener('DOMContentLoaded', function () {
  Sceon.RPopup = {};

  Sceon.RPopup.inProcess = false;
  Sceon.RPopup.url = '';
  Sceon.RPopup.rec_url = location.origin + location.pathname;
  Sceon.RPopup.type = '';
  Sceon.RPopup.location = '';

  Sceon.RPopup.Show = function (url) {
    $.ajax({
      url: url,
      method: 'GET',
      success: function (resp) {
        if (resp) {
          if (!$('#profile-dialog').length) {
            return;
          }
          $('#profile-dialog').html(resp);
          $('#profile-dialog .show-dialog').trigger('click');

          if (
            Sceon.RPopup.rec_url.length &&
            $('#profile-dialog input[name=link]')
          ) {
            $('#profile-dialog input[name=link]').val(Sceon.RPopup.rec_url);
            $('#profile-dialog input[name=rec_url]').val(Sceon.RPopup.rec_url);
          }
          $('#profile-dialog .close-btn').click(function () {
            $('#profile-dialog').dialog('close');
          });
          $('#profile-dialog').find('form').attr({
            method: 'POST',
            action: url,
          });

          $('#profile-dialog .submit-form').click(function (e) {
            // $('#profile-dialog .popup-form').submit();
            e.preventDefault();
            if (!$('input[name="geo_nodes"]').length) {
              Sceon.RPopup.Send();
            } else {
              Sceon.AlertMe.Send();
              return;
            }
          });
          $('#profile-dialog .reset-btn').click(function () {
            $('#profile-dialog input:not(.not-reset)').val('');
            $('#profile-dialog .input-wrapper').removeClass('invalid');
            $('#profile-dialog textarea').val('');
            $('#profile-dialog .error').html('');
            $('#profile-dialog select')
              .val($('#profile-dialog select option:first').val())
              .trigger('change.select2');
          });
          $('#profile-dialog .copy-link').click(function () {
            navigator.clipboard.writeText($('.copy-text').val());
            let $el = $(this);
            $el.find('.link-copied').removeClass('hidden');
            setTimeout(() => {
              $el.find('.link-copied').addClass('hidden');
            }, 3000);
          });
        }
      },
    });
  };

  Sceon.RPopup.Send = function () {
    event.preventDefault();
    let p_overlay = new Sceon.Overlay($('.modal-content'), {
      loader: '/imgr/loader-tiny.gif',
      offset: { bottom: 0, top: 0 },
    });

    p_overlay.enable();

    $.ajax({
      url: $('#profile-dialog .popup-form').attr('action'),
      method: 'POST',
      data: $('.popup-form').serialize(),
      success: function (resp) {
        if (resp.status === 'error') {
          $('#profile-dialog').find('.input-wrapper').removeClass('invalid');
          $(`#profile-dialog .error`).html('');

          for (let field in resp.msgs) {
            const errElm = $('*[name="' + field + '"]')
              .closest('.input-wrapper')
              .find('.error');
            $('#profile-dialog')
              .find('*[name="' + field + '"]')
              .closest('.input-wrapper')
              .addClass('invalid');
            if (errElm.length) {
              errElm.html(resp.msgs[field]);
            } else {
              $(`#profile-dialog .error#message`).html(resp.msgs[field]);
            }
          }
        } else if (resp.status === 'success') {
          $('#profile-dialog .show-dialog').trigger('click');
        }
        setTimeout(function () {
          p_overlay.disable();
        }, 100);
      },
    });
  };
});
