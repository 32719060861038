window.Timer = class {
  constructor({ seconds, callback = this.defaultCallback }) {
    this.seconds = seconds * 1000 || 0;
    this.callback = callback;
    this.options = {
      frequency: 250,
    };
    this.data = {
      isRunning: false,
    };
  }

  defaultCallback() {
    window.location = '/private/signout';
  }

  startTimer() {
    this.data.isRunning = true;
    this.data.startTime = new Date().getTime();
    this.data.endTime = this.data.startTime + this.seconds;
    this.updateTimer();
  }

  stopTimer() {
    this.data.isRunning = false;
  }

  updateTimer() {
    this.data.timeLeft = this.data.endTime - new Date().getTime();
    if (this.data.timeLeft <= 0) {
      this.data.isRunning = false;
      if (this.callback) {
        this.callback();
      }
      return;
    } else {
      if (this.data.isRunning) {
        let updateIn = Math.min(this.data.timeLeft, this.options.frequency);
        setTimeout(this.updateTimer.bind(this), updateIn);
      }

      if (this.data.obj) {
        this.data.obj.html(this.display(this.data.timeLeft));
      }
    }
  }

  setDisplay(obj) {
    this.data.obj = obj;
    if (this.data.obj) {
      this.data.obj.html(this.display(this.data.timeLeft));
    }
  }

  pretifyNum(num) {
    if (num < 10) {
      num = '0' + num;
    }
    return num;
  }

  display(timeLeft) {
    timeLeft = parseInt(timeLeft / 1000);

    let s = timeLeft % 60;

    timeLeft = parseInt(timeLeft / 60);

    let m = timeLeft % 60;

    let h = parseInt(timeLeft / 60);

    return this.pretifyNum(m) + ':' + this.pretifyNum(s);
  }
};
