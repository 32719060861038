document.addEventListener('DOMContentLoaded', function () {
  Sceon.Overlay = class {
    constructor(element, options) {
      this.element = element[0] || element;
      this.options = {
        opacity: 0.8,
        color: '#fff',
        loader: '/imgr/loader-big.gif',
        position: '140px',
        has_loader: true,
        overlay_class: 'overlay',
        relative: false,
        offset: {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        },
        z_index: 10,
        ...options,
      };
      this.overlay = this.render();
    }

    render() {
      let styles = {
        class: this.options.overlay_class,
        css: {
          'background-color': this.options.color,
          position: 'absolute',
          left: this.options.offset.left,
          top: this.options.offset.top,
          'z-index': this.options.z_index,
          opacity: this.options.opacity,
          width: '100%',
          height: '100%',
        },
      };
      if (this.options.has_loader) {
        styles.css = {
          ...styles.css,
          'background-repeat': 'no-repeat',
          'background-position': 'center',
          'background-image': 'url(' + this.options.loader + ')',
        };
      }
      if (this.options.relative) {
        styles.css = {
          ...styles.css,
          position: 'relative',
        };
      }
      let overlay = document.createElement('div');
      overlay.classList.add(styles.class);
      Object.assign(overlay.style, styles.css);

      this.element.style.position = this.options.p_absolute
        ? 'absolute'
        : 'relative';

      this.element.prepend(overlay);
      return overlay;
    }

    disable() {
      // this.overlay.addClass('overlay-hidden');
      this.overlay?.remove();
      this.overlay = null;
    }

    enable() {
      this.overlay.classList.remove('overlay-hidden');
    }
  };
});
