import 'select2';

function setupSelect() {
  $('select')
    .select2({
      minimumResultsForSearch: Infinity,
      placeholder: {
        id: null,
        text: null,
      },
      templateSelection: function (data) {
        const selectElement = $(data.element).closest('select');
        let label = $(selectElement).attr('data-label') || '';
        let floatedLabel = $(selectElement).attr('data-floated-label') || '';
        let default_value =
          $(data.element).closest('select').attr('data-default') || '';
        const element = $(data.element)
          .closest('label')
          .find('.select2-selection');
        const isUpdated = $(selectElement).attr('data-updated');

        if (floatedLabel) {
          $(selectElement)
            .next()
            .addClass('floated-label')
            .attr('data-floated-label', floatedLabel);
        }

        if (!isUpdated || isUpdated !== 'true') {
          $(selectElement).attr('data-updated', true);
        }

        if (label) {
          label = label.charAt(0).toUpperCase() + label.slice(1) + ': ';
        }

        if (!data.id) {
          return $(
            `<span class="sc-selection-label text-gray">${translations.lbl_select}</span>`,
          );
        }

        return $(
          `<span class="text-primary">${label}</span>
           <span class="sc-selection-label">${data.text}</span>`,
        );
      },
    })
    .hide();
}

const observeDOM = (function () {
  const MutationObserver =
    window.MutationObserver || window.WebKitMutationObserver;

  return function (obj, callback) {
    if (!obj || obj.nodeType !== 1) return;

    if (MutationObserver) {
      const mutationObserver = new MutationObserver(callback);

      mutationObserver.observe(obj, { childList: true, subtree: true });
      return mutationObserver;
    } else if (window.addEventListener) {
      obj.addEventListener('DOMNodeInserted', callback, false);
      obj.addEventListener('DOMNodeRemoved', callback, false);
    }
  };
})();

observeDOM(document.querySelector('body'), function (m) {
  m.forEach((record) => {
    record.addedNodes.forEach((node) => {
      if ($(node).find('select').length || node.nodeName === 'select') {
        setupSelect();
      }
    });
  });
});

document.addEventListener('DOMContentLoaded', function () {
  setupSelect();
});
