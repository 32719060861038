import $ from 'jquery';
import './splash';
import './overlay';
import './image-load';
import './select';

window.$ = $;
window.jQuery = $;
history.scrollRestoration = 'manual';
window.Sceon = {};
Sceon.ProfileComments = {};
Sceon.Feedback = {};
Sceon.Lang = {};

document.addEventListener('DOMContentLoaded', (event) => {
  window.scrollTo({ top: 0, behavior: 'auto' });

  Sceon.setPageCookie = function (cookieName, cookieValue, time = 0) {
    let date = new Date();
    if (time) {
      date.setTime(date.getTime() + time * 3600 * 1000);
    } else {
      date.setFullYear(date.getFullYear() + 10);
    }
    const isLocal = location.hostname.includes('local');

    const domain = isLocal ? null : `${headerVars.exteriorHost}`;
    const oldDomain = isLocal
      ? null
      : headerVars.exteriorHost.replace(/(dev|www)/g, '');

    function setCookie(domain, date) {
      const secure = !isLocal;
      let pageCookie = `${cookieName}=${cookieValue}; expires=${date.toUTCString()}; path=/`;

      if (domain) {
        pageCookie = `${pageCookie}; domain=${domain}`;
      }
      if (secure) {
        pageCookie = `${pageCookie}; secure=${secure}`;
      }
      document.cookie = pageCookie;
    }

    setCookie(oldDomain, new Date(0));
    setCookie(domain, date);
  };

  Sceon.getPageCookie = function (cookieName) {
    return Cookies.get(cookieName);
  };

  Sceon.removePageCookie = function (cookieName) {
    Cookies.remove(cookieName);
  };

  Sceon.ListTypeChanges = function () {
    $('.list-types a').on('click', function (e) {
      e.preventDefault();
      let mode = $(this).attr('data-mode');

      function escortsPageList(mode) {
        Sceon.setPageCookie('list_type', mode);
        $('.main-listing-container')
          .removeClass('xl-view grid-view list-view')
          .addClass(mode + '-view');
        $('.list-types').attr('class', 'list-types ' + mode + '-view');
      }

      escortsPageList(mode);

      $('.escorts-list .profile-photo').each(function (index, el) {
        const url = $(el)
          .attr('src')
          .replace(/thumb_\d+/i, mode === 'xl' ? 'thumb_420' : 'thumb_298');
        $(el).attr('src', url);
      });
    });

    function changeGrid() {
      if (
        window.innerWidth < 769 &&
        document
          .querySelector('.main-listing-container')
          ?.classList.contains('xl-view')
      ) {
        document
          .querySelector('.list-types a[data-mode="grid"]')
          .dispatchEvent(new Event('click'));
      }
    }

    changeGrid();
    window.addEventListener('resize', () => {
      changeGrid();
    });
  };

  function reloadAsGet(lang) {
    const loc = window.location;
    let search = loc.search;
    const key = 'lang';
    const value = lang;

    if (search.indexOf(`${key}=`) === -1) {
      const sep = search.indexOf('?') !== -1 ? '&' : '?';
      search += `${sep}${key}=${value}`;
    } else {
      const regex = new RegExp(`(${key}=)[^&]+`);
      search = search.replace(regex, `$1${value}`);
    }

    setTimeout(() => {
      window.location.href =
        loc.protocol + '//' + loc.host + loc.pathname + search + loc.hash;
    });
  }

  document.querySelectorAll('.ln-btn').forEach((elm) => {
    elm.addEventListener('click', function (e) {
      e.preventDefault();
      let lang = e.target.getAttribute('data-ln');
      Sceon.setPageCookie('ln', lang);
      reloadAsGet(lang);
    });
  });

  if (headerVars.currentUser !== '') {
    let timer = new Timer({ seconds: 60 * 60 });
    timer.startTimer();
    timer.setDisplay($('.exp-time'));
  }

  $('.reset-time').on('click', function (e) {
    e.preventDefault();
    window.location.href = location.href;
  });

  $('.go-to-top').on('click', function () {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      100,
    );
  });

  $(document).scroll(function () {
    let top = $(window).scrollTop();
    if (top > 0) {
      $('.go-to-top').removeClass('hidden');
    } else {
      $('.go-to-top').addClass('hidden');
    }
  });

  $(document)
    .find('.status-messages-close')
    .click(function () {
      Sceon.setPageCookie('status_messages_hidden', 1, 0.2);
      $('#latest-status-messages-carousel').remove();
    });

  if ($('.banner-box').length) {
    let banners = $('.banner-box a');
    let bannerCount = $('.banner-box a').length;

    let index = 0;
    if (bannerCount > 1) {
      setInterval(() => {
        if ($(banners[index])) {
          $(banners[index]).fadeOut();
          index++;
          if (index >= banners.length) {
            index = 0;
          }
          $(banners[index]).fadeIn();
        }
      }, 3500);
    }
  }

  if ($('.left-side-banner .sc-banners-list').length) {
    let banners = $('.left-side-banner .sc-banners-list a');
    let bannerCount = $('.left-side-banner .sc-banners-list a').length;

    let index = 0;
    if (bannerCount > 1) {
      setInterval(() => {
        if ($(banners[index])) {
          $(banners[index]).fadeOut();
          index++;
          if (index >= banners.length) {
            index = 0;
          }
          $(banners[index]).fadeIn();
        }
      }, 3500);
    }
  }
});

window.trimInputValue = function trimInputValue(maxLength) {
  if (event.target?.value.length > maxLength) {
    event.preventDefault();
    event.target.value = event.target.value.slice(0, maxLength);
  }
};
