document.addEventListener('DOMContentLoaded', function () {
  Sceon.Popup = {
    url: '/private/sign-in-up',
  };

  Sceon.Popup.Show = function () {
    if (Sceon.Popup.url != '') {
      $.ajax({
        url: Sceon.Popup.url,
        method: 'GET',
        success: function (resp) {
          if (!$('#profile-dialog').length) {
            return;
          }
          $('#profile-dialog').html(resp);
          $('#profile-dialog .show-dialog').trigger('click');

          Sceon.Popup.validateUsername();

          // $(window).resize(function () {
          //   $('#profile-dialog').dialog('option', 'position', {
          //     my: 'center',
          //     at: 'center',
          //     of: window,
          //   });
          // });

          $('#profile-dialog button').click(function () {
            $('#profile-dialog form').submit();
          });
          $('#profile-dialog form').on('submit', function (e) {
            e.preventDefault();
            Sceon.Popup.Send();
          });
        },
      });
    }
  };

  Sceon.Popup.validateUsername = function () {
    let $username = $('#profile-dialog input[name="username"]');
    $username.blur(function () {
      let val = $username.val();
      $username.removeClass('invalid');

      if (val.length < 6) {
        $username.addClass('invalid');
        return;
      }

      let regex = /^[-_a-z0-9]+$/i;
      if (!regex.test(val)) {
        $username.addClass('invalid');
        return;
      }

      $.ajax({
        url: '/private/check?username=' + val,
        method: 'GET',
        success: function (resp) {
          if (resp.status == 'found') {
            $username.addClass('invalid');
          } else if (resp.status == 'not found') {
            $username.removeClass('invalid');
          }
        },
      });
    });
  };

  Sceon.Popup.Send = function () {
    event.preventDefault();
    let p_overlay = new Sceon.Overlay($('#profile-dialog'), {
      loader: '/img/loader-tiny.gif',
      offset: { bottom: 0, top: 0 },
    });

    p_overlay.enable();

    let $popup = $('#profile-dialog form');
    let url = $popup.attr('action');
    $.ajax({
      url: url,
      method: 'POST',
      data: $popup.serialize(),
      success: function (resp) {
        if (resp.status == 'success') {
          window.location.reload();
        } else if (resp.status == 'error') {
          $(`#profile-dialog form .error`).html('');

          for (let i in resp.msgs) {
            const key = i === 'username' ? i : 'password';
            $(`#profile-dialog form .error#${key}`).html(resp.msgs[i]);
          }
          p_overlay.disable();
        } else if (resp.status == 'account_is_blocked') {
          window.location.href = '/private/signin';
        }
      },
    });
  };
});
