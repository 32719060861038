document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.splash-container')) {
    document.body.classList.add('splash-hidden');

    document
      .getElementById('enter-site')
      .addEventListener('click', function () {
        Sceon.setPageCookie('18', true);
        document.body.classList.remove('splash-hidden');
        document.querySelector('.splash-container').remove();
      });
  }
});
