document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelectorAll('.lazy-video-js').length) {
    loadVideoJS(() => {
      document.querySelectorAll('.lazy-video-js').forEach((element) => {
        if (element.tagName === 'VIDEO') {
          initVideoJS(element);
        } else {
          element.querySelectorAll('video').forEach((video) => {
            initVideoJS(video);
          });
        }
      });
    });
  }

  function loadVideoJS(onLoad) {
    if (
      typeof videojs === 'undefined' ||
      !document.querySelector('.video-js-script')
    ) {
      const script = document.createElement('script');
      script.src = '/js/video.min.js';
      script.className = 'video-js-script';
      script.async = true;
      script.onload = () => onLoad();
      document.head.appendChild(script);
    } else if (typeof videojs === 'undefined') {
      document
        .querySelector('.video-js-script')
        .addEventListener('load', () => {
          onLoad();
        });
    } else {
      onLoad();
    }
  }

  function initVideoJS(videoElement) {
    videojs(videoElement);
  }
});
